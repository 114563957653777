@media (min-width: 0px) and (max-width: 575px) {
  /*--------------- CONTAIN_START --------------*/
  .banner-block-overlay-hp {
    min-height: inherit; }
  .banner-img-hp {
    display: none; }
  .banner-text-hp {
    padding: 50px 0 0 0;
    max-width: 280px;
    margin: auto; }
  .banner-subtext-hp {
    padding: 30px 0;
    font-size: 15px;
    line-height: 28px; }
  .banner-block-in-hp {
    padding-bottom: 90px; }
  .nittetsu-block-hp {
    margin-top: 0px; }
  .nittetsu-block-in-hp:before {
    display: none; }
  .nittetsu-block-in-hp:after {
    display: none; }
  .nittetsu-middle-hp {
    padding: 40px 0; }
  .nittetsu-desc-hp {
    font-size: 12px;
    line-height: 26px;
    padding-bottom: 0px; }
  .service-block-hp {
    padding: 20px 0 0 0; }
  .service-middle-hp {
    margin-bottom: 40px; }
  .service-box-hp {
    height: auto;
    background: none; }
  .service-box-in-hp {
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 40px 0; }
  .service-box-hp:after {
    left: -15px;
    right: auto; }
  .service-title-hp {
    font-size: 20px;
    line-height: 46px; }
  .service-title-br {
    display: none; }
  .service-desc-hp {
    font-size: 13px;
    line-height: 24px; }
  .service-btn-hp {
    padding: 30px 0 0 0; }
  .advantage-block-hp {
    padding: 20px 0 0 0; }
  .advantage-img-hp {
    margin-top: 0px; }
  .advantage-btn-hp {
    margin-top: 0px;
    padding-bottom: 40px; }
  .production-block-hp {
    padding: 20px 0 0 0; }
  .production-middle-hp {
    margin: 30px 0 0 0; }
  .production-box-hp .service-box-in-hp {
    padding: 40px 0; }
  .production-box-hp .service-desc-hp {
    padding: 20px 0 0 0; }
  .production-box-hp .service-btn-hp {
    padding: 30px 0 0 0; }
  .company-block-hp {
    padding: 20px 0 0 0; }
  .company-img-hp {
    margin-top: 0px; }
  .company-block-hp:before {
    width: 180px; }
  .company-block-hp:after {
    left: -30px;
    top: -110px;
    width: 140px; }
  /*--------------- CONTAIN_END --------------*/ }

@media (min-width: 576px) and (max-width: 767px) {
  /*--------------- CONTAIN_START --------------*/
  .banner-block-overlay-hp {
    min-height: inherit; }
  .banner-img-hp {
    width: 120px;
    top: 40px; }
  .banner-text-hp {
    padding: 50px 0 0 0;
    max-width: 280px;
    margin: auto; }
  .banner-subtext-hp {
    padding: 30px 0;
    font-size: 15px;
    line-height: 28px; }
  .banner-block-in-hp {
    padding-bottom: 90px; }
  .nittetsu-block-hp {
    margin-top: 0px; }
  .nittetsu-block-in-hp:before {
    display: none; }
  .nittetsu-block-in-hp:after {
    display: none; }
  .nittetsu-middle-hp {
    padding: 40px 0; }
  .nittetsu-desc-hp {
    font-size: 12px;
    line-height: 26px;
    padding-bottom: 0px; }
  .service-block-hp {
    padding: 20px 0 0 0; }
  .service-middle-hp {
    margin-bottom: 40px; }
  .service-box-hp {
    height: auto;
    background: none; }
  .service-box-in-hp {
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 40px 0; }
  .service-box-hp:after {
    left: -15px;
    right: auto; }
  .service-title-hp {
    font-size: 20px;
    line-height: 46px; }
  .service-title-br {
    display: none; }
  .service-desc-hp {
    font-size: 13px;
    line-height: 24px; }
  .service-btn-hp {
    padding: 30px 0 0 0; }
  .advantage-block-hp {
    padding: 20px 0 0 0; }
  .advantage-img-hp {
    margin-top: -60px; }
  .advantage-btn-hp {
    margin-top: 0px;
    padding-bottom: 40px; }
  .production-block-hp {
    padding: 20px 0 0 0; }
  .production-middle-hp {
    margin: 30px 0 0 0; }
  .production-box-hp .service-box-in-hp {
    padding: 40px 0; }
  .production-box-hp .service-desc-hp {
    padding: 20px 0 0 0; }
  .production-box-hp .service-btn-hp {
    padding: 30px 0 0 0; }
  .company-block-hp {
    padding: 20px 0 0 0; }
  .company-img-hp {
    margin-top: -80px; }
  .company-block-hp:before {
    width: 180px; }
  .company-block-hp:after {
    left: -30px;
    top: -110px;
    width: 140px; }
  /*--------------- CONTAIN_END --------------*/ }

@media (min-width: 768px) and (max-width: 991px) {
  /*--------------- CONTAIN_START --------------*/
  .banner-block-overlay-hp {
    min-height: inherit; }
  .banner-img-hp {
    width: 180px;
    top: 60px; }
  .banner-text-hp {
    padding: 50px 0 0 0;
    max-width: 400px;
    margin: auto; }
  .banner-block-in-hp {
    padding-bottom: 190px; }
  .nittetsu-block-hp {
    margin-top: -40px; }
  .nittetsu-block-in-hp:before {
    left: -20px;
    top: -50px;
    width: 197px; }
  .nittetsu-block-in-hp:after {
    right: -180px;
    top: 0;
    width: 520px; }
  .nittetsu-middle-hp {
    padding: 40px 0; }
  .nittetsu-desc-hp {
    font-size: 12px;
    line-height: 26px;
    padding-bottom: 0px;
    max-width: 360px; }
  .service-block-hp {
    padding: 20px 0 0 0; }
  .service-middle-hp {
    margin-bottom: 40px; }
  .service-box-hp {
    height: auto; }
  .service-box-in-hp {
    padding: 60px 0; }
  .service-title-hp {
    font-size: 18px;
    line-height: 46px; }
  .service-title-br {
    display: none; }
  .service-desc-hp {
    font-size: 13px;
    line-height: 24px; }
  .service-btn-hp {
    padding: 30px 0 0 0; }
  .advantage-block-hp {
    padding: 20px 0 0 0; }
  .advantage-img-hp {
    margin-top: -60px; }
  .advantage-btn-hp {
    margin-top: 0px;
    padding-bottom: 40px; }
  .production-block-hp {
    padding: 20px 0 0 0; }
  .production-middle-hp {
    margin: 30px 0 0 0; }
  .production-box-hp .service-box-in-hp {
    padding: 40px 0; }
  .production-box-hp .service-desc-hp {
    padding: 20px 0 0 0; }
  .production-box-hp .service-btn-hp {
    padding: 30px 0 0 0; }
  .company-block-hp {
    padding: 20px 0 0 0; }
  .company-img-hp {
    margin-top: -80px; }
  .company-block-hp:before {
    width: 180px; }
  .company-block-hp:after {
    left: -30px;
    top: -110px;
    width: 140px; }
  /*--------------- CONTAIN_END --------------*/ }

@media (min-width: 992px) and (max-width: 1150px) {
  /*--------------- CONTAIN_START --------------*/
  .banner-block-overlay-hp {
    min-height: inherit; }
  .banner-img-hp {
    width: 180px;
    top: 60px; }
  .banner-text-hp {
    padding: 50px 0 0 0;
    max-width: 400px;
    margin: auto; }
  .banner-block-in-hp {
    padding-bottom: 190px; }
  .nittetsu-block-hp {
    margin-top: -40px; }
  .nittetsu-block-in-hp:before {
    left: -20px;
    top: -50px;
    width: 280px; }
  .nittetsu-block-in-hp:after {
    right: -180px;
    top: 0;
    width: 670px; }
  .nittetsu-middle-hp {
    padding: 40px 0; }
  .nittetsu-desc-hp {
    font-size: 12px;
    line-height: 26px;
    padding-bottom: 0px;
    max-width: 360px; }
  .advantage-img-hp {
    margin-top: -100px; }
  .company-img-hp {
    margin-top: -180px; }
  /*--------------- CONTAIN_END --------------*/ }

@media (min-width: 1151px) and (max-width: 1400px) {
  /*--------------- CONTAIN_START --------------*/
  .banner-img-hp {
    width: 250px;
    top: 150px; }
  .nittetsu-block-hp {
    margin-top: -40px; }
  .advantage-img-hp {
    margin-top: -100px; }
  .company-img-hp {
    margin-top: -180px; }
  /*--------------- CONTAIN_END --------------*/ }
